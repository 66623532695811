<template>
  <div>
    <h2 class="faledia-caption">Verfahrensweisen</h2>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/zr/verfahrensweisen_1.png')"
          parentId="zr"
          elementId="zr_verfahrensweisen_1.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Das schriftliche Rechnen ist „[…] dadurch gekennzeichnet, dass
        Zahlganzheiten in Ziffern zerlegt werden, die dann mit Hilfe des
        Einspluseins und des Einmaleins gemäß genau definierter Regeln
        mechanisch zu verknüpfen sind“ (Selter, 1999, S. 6). Das Operieren nach
        diesen genau definierten Regeln, die schematisch wiederholt angewendet
        werden, wird als Algorithmus bezeichnet (Greiler-Zauchner, 2022, S. 12).
        Abgrenzen lässt sich das schriftliche Rechnen folglich vom
        halbschriftlichen und mündlichen Rechnen, wobei im Gegensatz dazu mit
        (teilweise zerlegten) Zahlganzheiten umgegangen wird, und zwar nach
        nicht strikt festgelegten Vorgehensweisen (Selter, 1999, S. 6). Dazu
        werden Zahl- und Aufgabenbeziehungen ausgenutzt (siehe dazu
        <a href="/zahlenrechnen">Zahlenrechnen</a>). Um es plakativ
        auszudrücken: beim halbschriftlichen und mündlichen Rechnen werden
        Beziehungen und Strategien gezielt genutzt, wohingegen beim
        schriftlichen Rechnen Algorithmen ausgeführt werden (vgl. Selter, 2000).
      </p>
    </div>
    <p>
      Das schriftliche Rechnen bringt somit Vorteile und Nachteile zugleich mit
      sich: „Wer die Funktionsweise des Verfahrens nicht verstanden hat, kann es
      dennoch aufgrund des algorithmischen Charakters (>Rechen-Rezept&lt;)
      praktizieren, da die Regeln für die Ziffernmanipulation und die Notation
      vergleichsweise einfach zu behalten und die Teilaufgaben (aus dem
      Einmaleins oder Einspluseins) leicht zu berechnen sind“ (Krauthausen,
      2018, S. 90 f). Daraus folgt, dass einerseits eine Methode gegeben ist,
      mit der – sofern richtig angewendet – Aufgaben schnell, sicher und mit
      hoher Wahrscheinlichkeit richtig berechnet werden können. Ein
      schriftliches Vorgehen kann kognitiv entlastend wirken, da „nicht bei
      jedem Rechenschritt der Stellenwert der Ziffer mit ins Kalkül gezogen
      werden muss“ (Greiler-Zauchner, 2022, S. 6). Andererseits birgt es auch
      die Gefahr, dass Kinder eben nicht mehr viel über ihr rechnerisches
      Handeln nachdenken müssen und im Zweifel ggf. verständnislos zu einem
      Ergebnis kommen.
    </p>
    <p>
      Im Folgenden wird nun das Verfahren für die schriftliche Addition näher
      betrachtet. Verglichen mit den Algorithmen der schriftlichen Subtraktion,
      Multiplikation oder Division scheint der Algorithmus der schriftlichen
      Addition eher unkompliziert zu sein (Radatz et al. 1999, S. 121). Als
      Voraussetzung ist es dennoch wichtig, dass ein gefestigtes Verständnis des
      Bündelungsprinzips und der Stellenwertschreibweise vorliegt (Radatz et al.
      1999, S. 120).
    </p>
    <p>
      Bei der schriftlichen Addition werden die Summanden stellengerecht
      untereinandergeschrieben. Als Orientierungshilfe sollte dabei die
      Einerstelle dienen, welche bei allen Summanden genau untereinanderstehen
      muss, um dieselben entsprechenden Stellenwerte der Summanden miteinander
      zu verknüpfen. Zu Beginn kann es für die Lernenden hilfreich sein, die
      Stellenwerte (also H, Z, E für Hunderter, Zehner und Einer) oberhalb der
      Aufgabe zu notieren. Im Anschluss wird von rechts nach links jede Spalte
      ziffernweise addiert und das jeweilige Ergebnis darunter notiert. Dabei
      muss auf die automatisierten Aufgaben des kleinen Einspluseins
      zurückgegriffen werden (siehe hierzu auch
      <a href="/einspluseins">Einspluseins</a>).
    </p>
    <p>
      Stehen an einem Stellenwert zwei Ziffern, deren Summe zweistellig ist (im
      Beispiel 7 Z plus 5 Z), so findet ein Übertrag statt. Dieser wird als
      kleine Eins – die sog. Übertragseins – unterhalb des nächsthöheren
      Stellenwerts notiert (im Beispiel ein weiterer Hunderter). Bei der
      Berechnung im nächsten Stellenwert muss dieser Übertrag dann
      berücksichtigt werden. Bei der schriftlichen Addition können beliebig
      viele Summanden untereinandergeschrieben und nach dem gleichen Prinzip
      addiert werden. Bei mehreren Summanden können natürlich auch größere
      Überträge als 1 entstehen.
    </p>
    <AppExpandableImage
      class="bigImage"
      contain
      :src="require('@/assets/zr/verfahrensweisen_2.png')"
      parentId="zr"
      elementId="zr_verfahrensweisen_2.png"
    >
    </AppExpandableImage>
    <br />
    <p>
      Gerade zu Beginn des Lernprozesses ist es sinnvoll, explizit die
      Sprechweise mit einzubeziehen und diese auch schematisch zu gestalten
      (vgl. Greiler-Zauchner, 2022, S. 12), damit eine Bewusstheit über die
      jeweiligen Stellenwerte geschaffen wird. Wichtig dabei ist, dass auf
      Genauigkeit und Vollständigkeit geachtet wird, sodass sowohl das Kind also
      auch Sie als Lehrkraft die einzelnen Rechenschritte nachvollziehen können.
    </p>
    <AppExpandableImage
      class="bigImage"
      contain
      :src="require('@/assets/zr/verfahrensweisen_3.png')"
      parentId="zr"
      elementId="zr_verfahrensweisen_3.png"
    >
    </AppExpandableImage>
    <br />
    <p>
      Für Geübte birgt der schriftliche Additionsalgorithmus sicherlich kaum
      Herausforderungen und erscheint als der leichteste der schriftlichen
      Algorithmen (Selter & Zannetin, 2018, S. 87). Als Lehrkraft ist es
      durchaus sinnvoll, sich immer wieder vor Augen zu halten, dass neue
      Lerninhalte für Kinder anfangs Hürden bieten können. Daher kann es ratsam
      sein, sich diese noch einmal selbst vor Augen zu führen. Daher sind Sie
      nun aufgefordert, einmal in einem anderen Stellenwertsystem
      nachzuvollziehen, wie herausfordernd es sein kann, schriftlich zu
      addieren. Sollten Sie sich zuvor vergegenwärtigen wollen, wie in anderen
      Stellenwertsystemen vorgegangen wird, können Sie dies
      <a
        href="/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln"
        >hier</a
      >
      tun.
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :image="LBSTStempeln[0].image"
        :audio="LBSTStempeln[0].audio"
        :elements="LBSTStempeln[0].elements"
        :showAlternative="false"
      ></LernbausteinStempeln>
    </div>
    <AppLiteraturZR />
    <AppBottomNavZR
      next="/ziffernrechnen/hintergrundwissen/verstehensorientierte-erarbeitung"
      back="/ziffernrechnen/hintergrundwissen"
    />
  </div>
</template>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.image {
  max-width: 35%;
  margin-inline-end: 1%;
  margin-block-end: 1%;
}
.bigImage {
  max-width: 85%;
  margin: auto;
}
</style>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
import AppLiteraturZR from "@/common/AppLiteraturZR";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";

import LBST_Stempeln_Verfahren_Addition from "@/components/faledia/seiten/ziffernrechnen/LBST/LBST_Stempeln_Verfahren_Addition";

export default {
  components: {
    AppBottomNavZR,
    AppLiteraturZR,
    AppExpandableImage,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTStempeln: [LBST_Stempeln_Verfahren_Addition],
  }),
};
</script>
