function createObject() {
  let example = {
    id: "LBST_ZR_Stempeln_Verfahren_Addition",
    workOrder: "Betrachten Sie die folgende Aufgabe Stellenweise:",
    image: require("@/assets/zr/Stempeln_Verfahren_Addition.png"),
    elements: [
      [
        {
          question: `Welche Ziffer muss in der Summe an der 8<sup>0</sup>-Stelle stehen?`,
          answers: [
            {
              correct: false,
              text: "8 (ohne Übertrag in die nächsthöhere Stelle)",
              response:
                "Würde die Aufgabe im dekadischen System gerechnet, so wäre dies richtig. Im Stellenwertsystem zur Basis 8 müssen allerdings je 8 Elemente eines Stellenwerts zur nächsthöheren Stelle gebündelt werden. Daher ist dies hier nicht richtig.",
            },
            {
              correct: false,
              text: "1 (mit Übertrag in die nächsthöhere Stelle)",
              response:
                "Zwar ist richtig, dass hier gebündelt werden muss, doch verbleibt nicht ein Element an der 8<sup>0</sup>er-Stelle. Im Achtersystem müssen je 8 Elemente eines Stellenwerts zur nächsthöheren Stelle gebündelt werden. Somit verbleibt kein Element an der 8<sup>0</sup>er-Stelle übrig.",
            },
            {
              correct: true,
              text: "0 (mit Übertrag in die nächsthöhere Stelle)",
              response:
                "Genau. Im Stellenwertsystem zur Basis 8 müssen je 8 Elemente eines Stellenwerts zur nächsthöheren Stelle gebündelt werden. Somit entsteht ein Übertrag in der nächsthöheren Stelle und es verbleiben keine Elemente an der 8<sup>0</sup>er-Stelle. Die Ziffer 0 muss also hier eingetragen werden.",
            },
          ],
        },
      ],
      [
        {
          question: `Welche Ziffer muss in der Summe an der 8<sup>1</sup>-Stelle stehen?`,
          answers: [
            {
              correct: true,
              text: "3 (mit Übertrag in die nächsthöhere Stelle)",
              response:
                `Genau. Unter Einbezug des Übertrags aus der 8<sup>0</sup>er-Stelle entstehen bei der Addition (13)<sub>8</sub> 8<sup>1</sup>er. Somit bleiben 3 Elemente in der 8<sup>1</sup>er-Stelle übrig und der Übertrag 1 wird in der nächsthöheren Stelle notiert. Die Ziffer 3 muss in der Summe an der 8<sup>1</sup>er-Stelle eingetragen werden.`,
            },
            {
              correct: false,
              text: "2 (mit Übertrag in die nächsthöhere Stelle)",
              response:
                "Hier haben Sie vermutlich den Übertrag aus der 8<sup>0</sup>er-Stelle vergessen. Dies ist auch bei Kindern ein häufiger Grund für falsche Ergebnisse. Beziehen Sie den Übertrag aus der vorherigen Stelle in Ihre Überlegungen mit ein.",
            },
            {
              correct: false,
              text: "0 (mit Übertrag in die nächsthöhere Stelle)",
              response:
                "Würde die Aufgabe im dekadischen System gerechnet, so wäre dies richtig. Im Stellenwertsystem zur Basis 8 müssen allerdings je 8 Elemente eines Stellenwerts zur nächsthöheren Stelle gebündelt werden. Daher ist dies hier nicht richtig.",
            },
          ],
        },
      ],
      [
        {
          question: `Welche Ziffer muss in der Summe an der 8<sup>2</sup>-Stelle stehen?`,
          answers: [
            {
              correct: true,
              text: "5 (ohne Übertrag in die nächsthöhere Stelle)",
              response: `Genau. Unter Einbezug des Übertrags aus der 8<sup>1</sup>er-Stelle ist dies richtig. Die Summe der Aufgabe ist also (530)<sub>8</sub>.`,
            },
            {
              correct: false,
              text: "4 (mit Übertrag in die nächsthöhere Stelle)",
              response: `Hier haben Sie vermutlich den Übertrag aus der 8<sup>1</sup>er-Stelle vergessen. Dies ist auch bei Kindern ein häufiger Grund für falsche Ergebnisse. Beziehen Sie den Übertrag aus der vorherigen Stelle in Ihre Überlegungen mit ein.`,
            },
            {
              correct: false,
              text: "5 (mit Übertrag in die nächsthöhere Stelle)",
              response: "Im Stellenwertsystem zur Basis 8 müssen je 8 Elemente eines Stellenwerts zur nächsthöheren Stelle gebündelt werden. Dies wird hier nicht überschritten und somit entsteht kein Übertrag.",
            },
          ],
        },
      ],
    ],
  };

  return example;
}

export default createObject();
